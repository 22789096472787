<template>
  <div id="defaultId1">
    <div class="pb20">
      <div class="flex_center_between_box" v-if="profitablitylist?.length > 0">
        <gui_card id="ge6845" class="uc1096" label="" :isShowLabel="false" style="margin-right: 10px; flex: 1;height: auto;">
          <div>
            <h2 class="operations-head flex_center_between_box">
              <span class="head-titel detail_title_20">Credit Assessment
                <svg @click="createDialog = true" t="1718848057496" class="icon" viewBox="0 0 1024 1024" version="1.1"
                  style="margin-left: 1.4285rem; cursor: pointer" xmlns="http://www.w3.org/2000/svg" p-id="4445" width="2.285rem"
                  height="2.285rem">
                  <path
                    d="M935.812877 88.243078h-87.685776V29.784188a29.226886 29.226886 0 1 0-58.453771 0v58.45889H234.331789V29.784188a29.226886 29.226886 0 1 0-58.453771 0v58.45889H88.187123C39.762336 88.243078 0.501347 127.498948 0.501347 175.923735v759.945098c0 48.429907 39.260989 87.685776 87.685776 87.685776h847.625754c48.424787 0 87.685776-39.260989 87.685776-87.685776V175.928854c0-48.424787-39.260989-87.685776-87.685776-87.685776z m29.226886 847.625755a29.232005 29.232005 0 0 1-29.226886 29.232005H88.187123a29.232005 29.232005 0 0 1-29.226886-29.232005V351.300406h906.079526v584.568427z m0-643.027317H58.960237V175.928854a29.226886 29.226886 0 0 1 29.226886-29.226885h87.685776v29.226885a29.226886 29.226886 0 1 0 58.453771 0v-29.226885h555.341541v29.226885a29.226886 29.226886 0 1 0 58.453771 0v-29.226885h87.685776a29.226886 29.226886 0 0 1 29.226885 29.226885V292.841516z"
                    p-id="4446" fill="#1290C9"></path>
                  <path
                    d="M512 526.671958c24.179117 0 43.842888-19.663771 43.842888-43.842888A43.842888 43.842888 0 0 0 512 438.986182a43.807052 43.807052 0 0 0-43.842888 43.842888c0 24.173998 19.597218 43.842888 43.842888 43.842888zM745.825323 877.409942c24.184237 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.801932 43.801932 0 0 0-43.842888 43.842888c0.005119 24.179117 19.602337 43.842888 43.842888 43.842888zM512 702.03839c24.179117 0 43.842888-19.658651 43.842888-43.842888 0-24.240551-19.663771-43.837769-43.842888-43.837768a43.801932 43.801932 0 0 0-43.842888 43.837768A43.842888 43.842888 0 0 0 512 702.03839zM745.825323 526.671958c24.184237 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.807052 43.807052 0 0 0-43.842888 43.842888c0.005119 24.173998 19.602337 43.842888 43.842888 43.842888zM745.825323 702.03839c24.184237 0 43.842888-19.658651 43.842888-43.842888 0-24.240551-19.663771-43.837769-43.842888-43.837768a43.801932 43.801932 0 0 0-43.842888 43.837768c0.005119 24.184237 19.602337 43.842888 43.842888 43.842888zM512 877.409942c24.179117 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.801932 43.801932 0 0 0-43.842888 43.842888 43.842888 43.842888 0 0 0 43.842888 43.842888zM278.174677 877.409942c24.179117 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.801932 43.801932 0 0 0-43.842888 43.842888 43.842888 43.842888 0 0 0 43.842888 43.842888zM278.174677 702.03839c24.179117 0 43.842888-19.658651 43.842888-43.842888 0-24.240551-19.663771-43.837769-43.842888-43.837768a43.801932 43.801932 0 0 0-43.842888 43.837768 43.842888 43.842888 0 0 0 43.842888 43.842888zM278.174677 526.671958c24.179117 0 43.842888-19.663771 43.842888-43.842888a43.842888 43.842888 0 0 0-43.842888-43.842888 43.807052 43.807052 0 0 0-43.842888 43.842888c0 24.173998 19.597218 43.842888 43.842888 43.842888z"
                    p-id="4447" fill="#1290C9"></path>
                </svg>
              </span>
              <span class="ViewMore contclass" @click="scrollToTop()">Credit Assessment from the Related Companies</span>
            </h2>
            <tableVue :profitablitylist="profitablitylist" :yearslist="yearslist1" :untia="untia"
              :createDialog="createDialog" @createDialogcheck="createDialogcheck"></tableVue>
          </div>
        </gui_card>
        <gui_card id="ge6845" class="uc1096" label="" :isShowLabel="false" style="width: 35.71em; padding: 0px !important">
          <div class="">
            <div class="table">
              <div class="table_box">
                <span class="aaa">High Risk</span>
                <span class="bbb">Medium</span>
                <span class="ccc">Moderate</span>
                <span class="ddd">Average</span>
                <span class="fff">Lower Than Avg.</span>
                <span class="ggg">Low Risk</span>
                <span class="hhh">Credit Opinion</span>
                <div class="echarts" ref="riskRef"></div>
                <ul class="echarts-content">
                  <li class="echarts-item" v-for="(item, index) in cardlist" :key="index">
                    <span class="echarts-item-left">{{ item.title }}</span><span>{{ item.text }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </gui_card>
      </div>
      <gui_card v-show="Related" id="ge6845" class="uc1096 mt10" label="" :isShowLabel="false">
        <div>
          <h2 class="operations-head flex_center_between_box">
            <span class="head-titel detail_title_20">Related Credit Assessment</span>
            <svg @click="Related = false" t="1718863593010" class="icon" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="4791" width="1.85rem" height="1.85rem">
              <path
                d="M547.4 512l278.2-278.2c9.8-9.8 9.8-25.6 0-35.4-9.8-9.8-25.6-9.8-35.4 0L512 476.6 233.8 198.4c-9.8-9.8-25.6-9.8-35.4 0-9.8 9.8-9.8 25.6 0 35.4L476.6 512 198.4 790.2c-9.8 9.8-9.8 25.6 0 35.4 4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3L512 547.4l278.2 278.2c4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3c9.8-9.8 9.8-25.6 0-35.4L547.4 512z"
                fill="#333333" p-id="4792"></path>
            </svg>
          </h2>
          <!-- 关联公司 -->
          <div class="computer_right_cont_box" v-if="parentTablelist.length > 0">
            <div class="infinite-list-wrapper" v-infinite-scroll="infiniteScroll" style="overflow: auto"
              :infinite-scroll-disabled="false" :infinite-scroll-distance="5">
              <div class="ecahrts-header ecahrts-header-one" >
                    <span class="title-left">
                      <span style="cursor: pointer; margin-right: 5px; width: 360px" class="line_1 relate-left"
                       >Company Name</span>
                    </span>
                    <span class="relate-Financial" style="margin-left: 21%;font-family: 'DMSans Bold';">Association Explanation</span>
                    <span style="
                      display: flex;
                      justify-content: flex-start;
                      width: 280px;
                    " class="line_1 relate-Financial"></span>
                    <div class="head_loging">
                     
                    </div>
                  </div>
              <!-- <div v-infinite-scroll="load" infinite-scroll-disabled="disabled"> -->
              <div class="footer" v-for="(item, index) in parentTablelist" :key="index" v-loading="item.loading2">
                <div class="ecahrts-header">
                  <span class="title-left">
                    <span style="cursor: pointer; margin-right: 5px; width: 360px" class="line_1 relate-left"
                      @click="setid(item)">{{ item.nameEn }}</span>
                  </span>
                  <span class="relate-Financial" style="margin-left: 22%">
                    Financial Highlights</span>
                  <span style="
                      display: flex;
                      justify-content: flex-start;
                      width: 280px;
                    " class="line_1 relate-Financial">
                    Relations:{{ item.typeEn }}</span>

                  <div class="head_loging">
                    <el-switch active-color="#13ce66" :value="loginStatus1[index]"
                      @change="loginPop(index, item.aaaid, item.nameEn)"></el-switch>
                  </div>
                </div>
                <div v-show="loginStatus1[index]" class="ecahrtstu flex_center_between_box">
                  <div class="left_box">
                    <tableVuetwo :profitablitylist="item.profitablitylist1" :yearslist="item.yearslist" :dia="id3a"
                      :untia="untia"></tableVuetwo>
                  </div>
                  <div class="right_box">
                    <div class="ecahrtstutwo">
                      <div class="table">
                        <div class="table_box">
                          <span class="aaa">High Risk</span>
                          <span class="bbb">Medium</span>
                          <span class="ccc">Moderate</span>
                          <span class="ddd">Average</span>
                          <span class="fff">Lower Than Avg.</span>
                          <span class="ggg">Low Risk</span>
                          <span class="hhh">Credit Opinion</span>
                          <div class="echarts" ref="chartRef"></div>
                          <ul class="echarts-content">
                            <li class="echarts-item" v-for="(item, index) in item.relatedcardlist" :key="index">
                              <span class="echarts-item-left">{{ item.title }}</span><span>{{ item.text }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <tableVue
                    :profitablitylist="item.profitablitylist1"
                    :yearslist="item.yearslist"
                    :dia="id3a"
                    :untia="untia"
                  ></tableVue> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </gui_card>
    </div>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>
  <!--srcviewscompanydetailsBackgroundSnapshotvuehtmlEnd-->
</template>
<script>
// interfaceCode
//importStart
import {
  getrelatedCompanylist,
  getCreditAssessment,
} from "@/api/companyApi";
import rectcard from "../../../../components/rectCard/index.vue";
import content_text from "../../../../components/text_content/index.vue";
import gui_card from "../../../../components/gui_card/gui_card.vue";
import tableVue from "../CreditOpinion/table.vue";
import tableVuetwo from "../Performance/components/tabletwo.vue";
import ReLogin from "../../../../components/ReLogin.vue";

//importEnd

export default {
  name: "",

  props: {},

  components: {
    gui_card,
    tableVuetwo,
    rectcard,
    content_text,
    tableVue,
    ReLogin,
  },
  computed: {},
  created() {

  },
  mounted() {
    this.getlist();
    this.riskEchart();
    this.$emit("UpdatamarkTotalList", []);
  },
  data() {
    return {
      reLoginVis: false,
      page: {
        pageNo: 1,
        pageSize: 20,
        total: 0,
      },
      Related: false,
      untia: "",
      yearslist1: [],
      createDialog: false,
      parentTablelist: [],
      loginStatus1: [],
      id3a: [],
      profitablitylist: [
        {
          lists: [
            {
              change: "",
              value: "",
            },
            {
              change: "",
              value: "",
            },
            {
              change: "",
              value: "",
            },
          ],
          name: "Return on Equity:",
        },

        {
          lists: [
            {
              change: "",
              value: "",
            },
            {
              change: "",
              value: "",
            },
            {
              change: "",
              value: "",
            },
          ],
          name: "Profit Margin:",
        },
      ],
      profitablitylist1: [
        {
          lists: [
            {
              change: "",
              value: "",
            },
            {
              change: "",
              value: "",
            },
            {
              change: "",
              value: "",
            },
          ],
          name: "Return on Equity:",
        },

        {
          lists: [
            {
              change: "",
              value: "",
            },
            {
              change: "",
              value: "",
            },
            {
              change: "",
              value: "",
            },
          ],
          name: "Profit Margin:",
        },
      ],
      companyList: [],
      noMore: false, // 控制滚动禁用
      routeLoad: false, // 控制滚动禁用
      icon: "",
      showBackToTop: false,
      newDialog: false,
      creditOpinion: "",
      creditScore: "",
      cardlist: [
        {
          title: "Local Credit Score:",
          text: "-",
        },

        {
          title: "Solvency Index:",
          text: "-",
        },
        {
          title: "Probability of Default:",
          text: "-",
        },
        {
          title: "Credit Line:",
          text: "-",
        },
      ],

      // srcviewscompanydetailsBackgroundSnapshotvueDataEnd
    };
  },
  methods: {
    scrollToTop() {
      if(this.parentTablelist.length>0){
        // this.Related = true;
      }else{
        this.getrelateList();
      }
     
      this.Related = true

    },
    createDialogcheck(value) {
      this.createDialog = value;
    },
    getlist() {
      const params = {
        id3a: this.$route.query.id3a,
      };
      getCreditAssessment(params).then(res => {
        this.loading = false;
        //  JSON.parse(crypto.decrypt(res))
        let data = res;
        if (data.code) {
          this.loading = false;
          let { ca, creditBO, years, unit } = data.data;
          this.untia = unit;
          this.profitablitylist = ca;
          this.yearslist1 = years;
          this.cardlist.map((item, index) => {

            if (item.title == 'Local Credit Score:') {
              item.text = creditBO.creditScore;
            }
            if (item.title == 'Probability of Default:') {
              item.text = creditBO.pd;
            }
            if (item.title == 'Solvency Index:') {
              item.text = creditBO.si;
            }
            if (item.title == 'Credit Line:') {
              item.text = creditBO.creditLine;
            }
          });
          this.creditOpinion = creditBO?.creditOpinion
          this.creditScore = Number(creditBO?.creditScore) / 100

        } else {
          this.loading = false;
          this.creditOpinion = 'unrated';
          this.creditScore = 0;
        }
        this.$nextTick(() => {
          this.riskEchart();
        });

      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      });


    },
    infiniteScroll() {
      this.routeLoad = false;
    },
    getrelateList() {


      getrelatedCompanylist({ id3a: this.$route.query.id3a, type: 1, page: this.page.pageNo, pageSize: this.page.pageSize, resourceType: 1 }).then(res => {
        if (res) {
          let list = res.data.rows;
          this.parentTablelist = res.data.rows;

          this.routeLoad = false;
          // this.parentTablelist = res.data.rows;

          this.parentTablelist.map(item => {
            item.profitablitylist1 = [];
            item.loading2 = false;
            item.yearslist = []
            item.chartInstance = null;
            item.relatedcardlist = [
        {
          title: "Local Credit Score:",
          text: "-",
        },

        {
          title: "Solvency Index:",
          text: "-",
        },
        {
          title: "Probability of Default:",
          text: "-",
        },
        {
          title: "Credit Line:",
          text: "-",
        },
      ];
          });
        }
      });
    },
    async loginPop(index, id, nameEn) {
      this.indexclose = index;
      this.$set(this.loginStatus1, index, !this.loginStatus1[index]);
      if (this.loginStatus1[index]) {
        //   this.loading1=true
        this.parentTablelist[index].loading2 = true;
        const params = {
          id3a: id,

        };

        if (true) {

          this.getparamslist(params, index);
        } 
      }
    },
    // 获取数据
    getparamslist(params, index) {
      getCreditAssessment(params).then(res => {
     
        this.parentTablelist[index].loading2 = false;
        let data = res

        let { ca, creditBO, years, unit } = data.data;
        this.parentTablelist[index].yearslist = years;
        this.id3a = years;
        this.parentTablelist[index].profitablitylist1 = ca;
        this.untia = unit;
        let that = this;
        that.loginStatus1[index] = true;
        this.parentTablelist[index].relatedcardlist.map((item, index) => {
          if (item.title == 'Local Credit Score:') {
            item.text = creditBO.creditScore;
          }
          if (item.title == 'Probability of Default:') {
            item.text = creditBO.pd;
          }
          if (item.title == 'Solvency Index:') {
            item.text = creditBO.si;
          }
          if (item.title == 'Credit Line:') {
            item.text = creditBO.creditLine;
          }
        });

        this.creditOpinion = creditBO?.creditOpinion
        this.creditScore = Number(creditBO?.creditScore) / 100
        // const chartDom = this.$refs.chartRef[index];
        // const chart = this.$echarts.getInstanceByDom(chartDom) || this.$echarts.init(chartDom);
        const option = {
          grid: {
            left: "0",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          //
          series: [
            {
              type: "gauge",
              startAngle: 180,
              endAngle: 0,

              center: ["50%", "75%"],
              radius: "146%",
              // radius: '145%',
              clockwise: true,
              show: true,
              min: 0,
              max: 1,
              splitNumber: 5,

              axisLine: {
                roundCap: true,
                lineStyle: {
                  width: 20,

                  color: [
                    [
                      0.25,
                      {
                        type: "linear",

                        x: 0,

                        y: 0,

                        x2: 0,

                        y2: 1,

                        colorStops: [
                          {
                            offset: 1,
                            color: "#F56B6BFF",
                          },
                        ],

                        global: false,
                      },
                    ],

                    [
                      0.5,
                      {
                        type: "linear",

                        x: 0,

                        y: 0,

                        x2: 0,

                        y2: 1,

                        colorStops: [
                          {
                            offset: 0,
                            color: "#FFDC17FF",
                          },
                          {
                            offset: 1,
                            color: "#FA6666FF",
                          },
                        ],

                        global: false,
                      },
                    ],

                    [
                      1,
                      {
                        type: "linear",

                        x: 0,

                        y: 0,

                        x2: 0,

                        y2: 1,

                        colorStops: [
                          {
                            offset: 0,
                            color: "#FFDC17FF",
                          },
                          {
                            offset: 1,
                            color: "#55B419FF",
                          },
                        ],

                        global: false,
                      },
                    ],
                  ],
                },
              },

              pointer: {
                icon: "circle",

                length: "20%",
                width: 22,
                offsetCenter: [0, "-85%"],
                itemStyle: {
                  color: "auto",
                },
              },
              axisTick: {
                length: 5,
                distance: -28,
                lineStyle: {
                  color: "auto",
                  width: 2,
                },
              },
              // 刻度线
              splitLine: {
                show: false,
                length: 10,
                // distance:-20,
                lineStyle: {
                  color: "auto",
                  width: 8,
                },
              },
              axisLabel: {
                show: false,
                rotate: "tangential",
                fontWeight: "normal",
                fontSize: 0,
                distance: -62,
                width: 70,
                height: 40,
                fontFamily: "Arial",
                overflow: "truncate",
                color: [
                  [
                    0.25,
                    {
                      type: "linear",

                      x: 0,

                      y: 10,

                      x2: 30,

                      y2: 11,

                      colorStops: [
                        {
                          offset: 1,
                          color: "#F56B6BFF",
                        },
                      ],

                      global: false,
                    },
                  ],

                  [
                    0.5,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 0,
                          color: "#FFDC17FF",
                        },
                        {
                          offset: 1,
                          color: "#FA6666FF",
                        },
                      ],

                      global: false,
                    },
                  ],

                  [
                    1,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 0,
                          color: "#FFDC17FF",
                        },
                        {
                          offset: 1,
                          color: "#55B419FF",
                        },
                      ],

                      global: false,
                    },
                  ],
                ],

                formatter: function (value) {
                  if (value === 1) {
                    return "Low Risk";
                  } else if (value === 0.8) {
                    return "Lower Than Avg.";
                  } else if (value === 0.6) {
                    return "Average";
                  } else if (value === 0.4) {
                    return "Moderate";
                  } else if (value === 0.2) {
                    return "Medium";
                  } else if (value === 0.0) {
                    return "High Risk";
                  }
                  return "";
                },
              },

              title: {
                offsetCenter: [0, "20%"],
                fontSize: 15,
                color: "#ccc",
              },
              // 仪表盘数字
              detail: {
                lineHeight: 126,
                fontSize: 35,
                offsetCenter: [1, "-22"],
                valueAnimation: true,

                borderColor: "#55B419FF",

                width: 200,
                formatter: function (value) {
                  var a = "d";
                  if (Math.round(value * 100)) {
                    a = "A";
                  }
                  return that.creditOpinion;
                },
                color: "#55B419FF",
              },
              data: [
                {
                  value: that.creditScore,
                  // name: "Credit Opinion",
                },
              ],
            },
            {
              type: "gauge",
              startAngle: 180,
              endAngle: 0,
              center: ["50%", "75%"],
              radius: "96%",
              min: 0,
              max: 1,

              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  width: 1,
                  color: [
                    [
                      0.5,
                      {
                        type: "linear",

                        x: 0,

                        y: 0,

                        x2: 0,

                        y2: 1,

                        colorStops: [
                          {
                            offset: 0,
                            color: "#FFDC17FF",
                          },
                          {
                            offset: 1,
                            color: "#FA6666FF",
                          },
                        ],

                        global: false,
                      },
                    ],

                    [
                      1,
                      {
                        type: "linear",

                        x: 0,

                        y: 0,

                        x2: 0,

                        y2: 1,

                        colorStops: [
                          {
                            offset: 0,
                            color: "#FFDC17FF",
                          },
                          {
                            offset: 1,
                            color: "#55B419FF",
                          },
                        ],

                        global: false,
                      },
                    ],
                  ],
                },
              },
              pointer: {
                icon: "triangle",
                length: "12%",
                width: 20,
                offsetCenter: [0, "-105%"],
                itemStyle: {
                  color: "#55B419FF",
                },
              },

              data: [
                {
                  value: that.creditScore,
                  detail: {
                    show: false,
                  },
                },
              ],
            },
          ],
        };

        const chartDom = this.$refs.chartRef[index];
        const chart = this.$echarts.getInstanceByDom(chartDom) || this.$echarts.init(chartDom);
        chart.setOption(option);
        this.parentTablelist[index].chartInstance = chart;
      });
    },

    riskEchart() {
      const that = this;
      const option = {
        grid: {
          left: "0",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        //
        series: [
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,

            center: ["50%", "75%"],
            radius: "146%",
            // radius: '145%',
            clockwise: true,
            show: true,
            min: 0,
            max: 1,
            splitNumber: 5,

            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 20,

                color: [
                  [
                    0.25,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 1,
                          color: "#F56B6BFF",
                        },
                      ],

                      global: false,
                    },
                  ],

                  [
                    0.5,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 0,
                          color: "#FFDC17FF",
                        },
                        {
                          offset: 1,
                          color: "#FA6666FF",
                        },
                      ],

                      global: false,
                    },
                  ],

                  [
                    1,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 0,
                          color: "#FFDC17FF",
                        },
                        {
                          offset: 1,
                          color: "#55B419FF",
                        },
                      ],

                      global: false,
                    },
                  ],
                ],
              },
            },

            pointer: {
              icon: "circle",

              length: "20%",
              width: 22,
              offsetCenter: [0, "-85%"],
              itemStyle: {
                color: "auto",
              },
            },
            axisTick: {
              length: 5,
              distance: -28,
              lineStyle: {
                color: "auto",
                width: 2,
              },
            },
            // 刻度线
            splitLine: {
              show: false,
              length: 10,
              // distance:-20,
              lineStyle: {
                color: "auto",
                width: 8,
              },
            },
            axisLabel: {
              show: false,
              rotate: "tangential",
              fontWeight: "normal",
              fontSize: 0,
              distance: -62,
              width: 70,
              height: 40,
              fontFamily: "Arial",
              overflow: "truncate",
              color: [
                [
                  0.25,
                  {
                    type: "linear",

                    x: 0,

                    y: 10,

                    x2: 30,

                    y2: 11,

                    colorStops: [
                      {
                        offset: 1,
                        color: "#F56B6BFF",
                      },
                    ],

                    global: false,
                  },
                ],

                [
                  0.5,
                  {
                    type: "linear",

                    x: 0,

                    y: 0,

                    x2: 0,

                    y2: 1,

                    colorStops: [
                      {
                        offset: 0,
                        color: "#FFDC17FF",
                      },
                      {
                        offset: 1,
                        color: "#FA6666FF",
                      },
                    ],

                    global: false,
                  },
                ],

                [
                  1,
                  {
                    type: "linear",

                    x: 0,

                    y: 0,

                    x2: 0,

                    y2: 1,

                    colorStops: [
                      {
                        offset: 0,
                        color: "#FFDC17FF",
                      },
                      {
                        offset: 1,
                        color: "#55B419FF",
                      },
                    ],

                    global: false,
                  },
                ],
              ],

              formatter: function (value) {
                if (value === 1) {
                  return "Low Risk";
                } else if (value === 0.8) {
                  return "Lower Than Avg.";
                } else if (value === 0.6) {
                  return "Average";
                } else if (value === 0.4) {
                  return "Moderate";
                } else if (value === 0.2) {
                  return "Medium";
                } else if (value === 0.0) {
                  return "High Risk";
                }
                return "";
              },
            },

            title: {
              offsetCenter: [0, "20%"],
              fontSize: 15,
              color: "#ccc",
            },
            // 仪表盘数字
            detail: {
              lineHeight: 126,
              fontSize: 35,
              offsetCenter: [1, "-22"],
              valueAnimation: true,

              borderColor: "#55B419FF",

              width: 200,
              formatter: function (value) {
                var a = "d";
                if (Math.round(value * 100)) {
                  a = "A";
                }
                return that.creditOpinion;
              },
              color: "#55B419FF",
            },
            data: [
              {
                value: that.creditScore,
                // name: "Credit Opinion",
              },
            ],
          },
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            center: ["50%", "75%"],
            radius: "96%",
            min: 0,
            max: 1,

            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                width: 1,
                color: [
                  [
                    0.5,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 0,
                          color: "#FFDC17FF",
                        },
                        {
                          offset: 1,
                          color: "#FA6666FF",
                        },
                      ],

                      global: false,
                    },
                  ],

                  [
                    1,
                    {
                      type: "linear",

                      x: 0,

                      y: 0,

                      x2: 0,

                      y2: 1,

                      colorStops: [
                        {
                          offset: 0,
                          color: "#FFDC17FF",
                        },
                        {
                          offset: 1,
                          color: "#55B419FF",
                        },
                      ],

                      global: false,
                    },
                  ],
                ],
              },
            },
            pointer: {
              icon: "triangle",
              length: "12%",
              width: 20,
              offsetCenter: [0, "-105%"],
              itemStyle: {
                color: "#55B419FF",
              },
            },

            data: [
              {
                value: that.creditScore,
                detail: {
                  show: false,
                },
              },
            ],
          },
        ],
      };
      this.riskChart = this.$echarts.init(this.$refs.riskRef);
      this.riskChart.setOption(option);
    },
    // insertMethod
    // srcviewscompanydetailsBackgroundSnapshotvueMethodStart
    // default
    default() { },
    // srcviewscompanydetailsBackgroundSnapshotvueMethodEnd
  },

};
</script>
<style scoped>
.uPdtqFl {
  padding-right: 2.857142857142857rem;
  padding-top: 2.142857142857143rem;
  padding-left: 2.857142857142857rem;
  padding-bottom: 2.142857142857143rem;
  box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border-top-left-radius: 0.2857142857142857rem;
  border-bottom-left-radius: 0.2857142857142857rem;
  border-top-right-radius: 0.2857142857142857rem;
  border-bottom-right-radius: 0.2857142857142857rem;
}

.importDetail-head {
  display: flex;
}

.flex_center_between_box {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.contclass {
  font-size: 1.4285714285714286rem;
  color: #238ACC;
  font-weight: normal;
  font-style: normal;
  cursor: pointer;
}

.ViewMore {
  line-height: 1.8571428571428572rem;
  text-align: left;
  font-style: normal;
  text-decoration-line: underline;
  margin-left: 2.142857142857143rem;
}

.uc1096 {
  height: 100%;
}

.head-titel {
  font-family: 'DMSans Bold';
  font-weight: 400;
  font-size: 2.142857142857143rem;
  display: flex;
  align-items: center;
}

.operations-head {
  height: 2.857142857142857rem;
  line-height: 2.857142857142857rem;
  font-family: 'DMSans Bold';
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-left {
  display: flex;
  font-size: 1.1428571428571428rem;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #022955;
  padding-left: 1.4285714285714286rem;
  background-color: #f2f5f9;
}

.footer {
  padding-left: 0rem;
  overflow: hidden;
}

.ecahrts-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.07142857142857142rem solid #cccccc;
}

.title-left {
  display: flex;
  align-items: center;
  padding-bottom: 0.9285714285714286rem;
  padding-top: 0.9285714285714286rem;
}

.td {
  margin: 1.0714285714285714rem 0 0 1.7857142857142858rem;
}

.relate-left {
  height: 4.142857142857143rem;
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  line-height: 4.142857142857143rem;
  text-align: left;
  font-family: 'DMSans Bold';
}

.relate-Financial {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  font-size: Arial;
}

.computer_right_cont_box {
  margin-top: 1.4285714285714286rem;
}

.tablewieth {
  width: 70%;
}

.aaa {
  font-size: 0.7142857142857143rem;
  color: #fa6666;
  position: absolute;
  left: 2.142857142857143rem;
  top: 14.285714285714286rem;
}

.bbb {
  font-size: 0.7142857142857143rem;
  color: #fa755c;
  position: absolute;
  left: 2.5rem;
  top: 6.071428571428571rem;
}

.ccc {
  font-size: 0.7142857142857143rem;
  color: #fc9249;
  position: absolute;
  left: 7.5rem;
  top: 0.5714285714285714rem;
}

.ddd {
  font-size: 0.7142857142857143rem;
  color: #ffd81a;
  position: absolute;
  left: 23.214285714285715rem;
  top: 0.5714285714285714rem;
}

.fff {
  font-size: 0.7142857142857143rem;
  color: #aec918;
  position: absolute;
  width: 3.642857142857143rem !important;
  height: 2.142857142857143rem;
  top: 6.071428571428571rem;
  left: 30rem;
}

.ggg {
  font-size: 0.7142857142857143rem;
  color: #63b819;
  position: absolute;
  left: 28.928571428571427rem;

  top: 14.285714285714286rem;
}

.hhh {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  position: absolute;
  left: 13.428571428571429rem;
  top: 14.285714285714286rem;
}

.echarts {
  width: 30.714285714285715rem;
  height: 17.857142857142858rem;
  padding-left: 3.2857142857142856rem;
}

.table_box {
  position: relative;
  margin-top: 6.428571428571429rem;
  margin-bottom: 6.5rem;
}

.echarts-content {
  margin-top: 2.857142857142857rem;
  padding: 0 4.285714285714286rem;
}

.echarts-item {
  display: flex;
  justify-content: flex-start;
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  line-height: 1.6428571428571428rem;
  margin-bottom: 2.142857142857143rem;
}

.echarts-item-left {
  display: inline-block;
  width: 15.714285714285714rem;
  margin-right: 1.4285714285714286rem;
  font-family: 'DMSans Bold';
}

.ecahrtstutwo {
  position: relative;
  left: 1.4285714285714286rem;
}

.uUIRzUi {
  padding: 2.142857142857143rem 2.857142857142857rem;
}
.left_box{
  width: 91%;

}
.right_box{
  width: 40%;
  background-color: #fff;
}
.ecahrts-header-one{
  background-color: #f2f5f9;
  height: 3.57142857rem;
    line-height: 3.57142857rem;
}
</style>
